// Google Analytics Account
// - https://developers.google.com/analytics/devguides/collection/analyticsjs
// - https://developers.google.com/analytics/devguides/collection/analyticsjs/debugging
//
// NOTE: Set your Property ID in .env (e.g. UA-00000000-0)

const environment = process.env.RAILS_ENV
const propertyId = process.env.GOOGLE_ANALYTICS

if (environment === 'production') {
  // Source: https://developers.google.com/analytics/devguides/collection/analyticsjs
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', propertyId, 'auto');
  ga('send', 'pageview');
} else {
  console.log(`(skipped analytics in ${environment})`)
}
